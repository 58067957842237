<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/device')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">
            <div class="">

                <div class="flex flex-wrap items-center content-between justify-between">
                    <span class="font-semibold text-base">{{ title }}</span>
                    <div class="flex">
                        <div class="text-xs relative mr-4">
                            <div class="absolute top-2.5 left-2.5">
                                <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>

                            </div>
                            <date-picker format="YYYY-MM-DD HH:mm" v-model="start" type="datetime"
                                @change="changeDate()" placeholder="Mulai" class="mr-2"></date-picker>
                            <date-picker format="YYYY-MM-DD HH:mm" v-model="end" type="datetime" @change="changeDate()"
                                placeholder="Akhir"></date-picker>
                        </div>

                    </div>

                </div>
                <div class="mt-4 w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                <td class="py-4">Ketinggian Air</td>
                                <td>Tgl</td>

                                <td>Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id"
                                class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                <td class="py-4">
                                    <div class="flex items-center">
                                        {{ parseFloat(item.real_ketinggian * 100).toFixed(2) }} cm
                                    </div>
                                </td>
                                <td class="py-4">
                                    {{ parseDate(item.last_update, 'YYYY-MM-DD HH:mm') }}
                                </td>

                                <td>
                                    <button class="text-white rounded py-2 px-2 ml-1 text-xs bg-blue-500 mr-1"
                                        @click="openItem(item)">
                                        Sesuaikan
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="flex justify-end items-center mt-4">

                        <div class="flex items-center ">
                            <div class="font-medium">
                                <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                            </div>
                            <div class="flex">
                                <unicon name="angle-left" class="cursor-pointer"
                                    :fill="currentPage > 1 ? '#00000' : '#A1A1AA'" @click="previousPage()"></unicon>
                                <unicon name="angle-right" class="cursor-pointer"
                                    :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                            </div>
                        </div>

                    </div>
                </div>
                <dialog-modal v-on:closeModal="closeModal" v-if="modal_dialog" :lebar="20">
                    <p class="font-bold">Sesuaikan data</p>
                    <br>
                    <table class="w-full">
                        <tr>
                            <td class="py-1">Ketinggian Sekarang</td>
                            <td class="py-1"> {{ parseFloat(item.real_ketinggian * 100).toFixed(2) }} cm</td>
                        </tr>
                        <tr>
                            <td class="py-1">Ketinggian Sebenarnya</td>
                            <td class="py-1">
                                <input v-model="adjustment_ketinggian" type="text"
                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs`" />
                            </td>
                        </tr>
                        <tr>
                            <td class="py-1">Sesuaikan untuk seterusnya</td>
                            <td class="py-1"><input v-model="implement_after" type="checkbox" name="" id="">
                                <br>
                                <span class="text-red-500">(peringatan : akan mengubah titik nol. disarankan untuk
                                    memakai menu kalibrasi)</span>
                            </td>
                        </tr>
                    </table>

                    <div class="grid grid-cols-2 gap-4 mt-4">
                        <button @click="closeModal" type="submit" :class="`col-span-1 text-center py-2 rounded
                                    
                                        bg-gray-100
                                        
                                        disabled:opacity-50
                                        focus:outline-none my-1`">Batal</button>
                        <button @click="simpanData()" type="submit" :class="`col-span-1 text-center py-2 rounded
                                        text-white
                                        bg-blue-500
                                        disabled:opacity-50
                                        focus:outline-none my-1`">Simpan</button>
                    </div>
                    <!-- <input
                        v-model="addData.company_name"
                        type="text"
                        :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                        name="company_name"
                        ref="company_name"
                        id="company_name"
                        /> -->
                </dialog-modal>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'
import dialogModal from '../../components/global/dialogMogal.vue'
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';

export default {
    data: () => ({
        data: [],
        totalRows: 100,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        delete_id: '',
        title: '',
        item: '',
        adjustment_ketinggian: 0,
        implement_after: false,
        start: new Date(),
        end: new Date(),
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {
        getDevice() {
            this.axios.get('v1/device/' + this.$route.params.id, this.config)
                .then((ress) => {
                    this.title = 'Data Perangkat ' + ress.data.name
                })
        },
        getData() {
            let start = moment(this.start).format('YYYY-MM-DD HH:mm')
            let end = moment(this.end).format('YYYY-MM-DD HH:mm')
            this.axios.get('v2/adjustment?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}&id=${this.$route.params.id}&start=${start}&end=${end}`, this.config)
                .then((ress) => {
                    console.log(ress)
                    this.data = ress.data.rows
                    this.totalRows = ress.data.count
                    if (this.totalRows <= this.limit) {
                        this.endNumber = this.totalRows
                    } else if (this.currentPage > 1) {
                        let page = this.currentPage - 1
                        this.startNumber = page * this.limit + 1
                        this.endNumber = this.currentPage * this.limit
                        if (this.totalRows < this.endNumber) {
                            this.endNumber = this.totalRows
                        }

                    } else {
                        this.startNumber = 1
                        this.endNumber = this.limit
                    }
                    this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1) * this.limit + 1
                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },
        openItem(item) {
            this.item = item
            this.closeModal()
        },

        simpanData() {
            this.axios.post('v2/adjustment' + this.delete_id, {
                adjustment_ketinggian: this.adjustment_ketinggian,
                data_device_id: this.item.id,
                implement_after: this.implement_after

            }, this.config)
                .then((ress) => {
                    this.closeModal()
                    this.$snack.success({
                        text: ress.data.message,

                    })
                })
        },
        changeDate() {
            this.getData()
        },
        closeModal() {
            this.implement_after = false
            this.modal_dialog = !this.modal_dialog
        },
        refres() {
            this.axios.get('v1/device/get', this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,

                    })
                })
        },
        eventChange(event) {
            this.default_url = ''
            const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.data.foto = fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
        },

    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),

    },
    created() {
        this.getDevice()
        this.getData()
    },
    components: {
        deleteComponent, DatePicker, dialogModal
    }
}
</script>